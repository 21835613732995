/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/

//import 'core-js/es7/object';
//import 'core-js/es7/array';

(function (document) {
    var currentScript = 'currentScript';
    if (!(currentScript in document)) {
        Object.defineProperty(document, currentScript, {
            get: function () {
                try {
                    throw new Error();
                } catch (err) {
                    var i = 0,
                        stackDetails = (/.*at [^(]*\((.*):(.+):(.+)\)$/ig).exec(err.stack),
                        scriptLocation = (stackDetails && stackDetails[1]) || false,
                        line = ((stackDetails && stackDetails[2]) || 0) as number,
                        currentLocation = document.location.href.replace(document.location.hash, ''),
                        pageSource,
                        inlineScriptSourceRegExp,
                        inlineScriptSource,
                        scripts = document.getElementsByTagName('script'); // Live NodeList collection

                    if (scriptLocation === currentLocation) {
                        pageSource = document.documentElement.outerHTML;
                        inlineScriptSourceRegExp = new RegExp('(?:[^\\n]+?\\n){0,' + (line - 2) + '}[^<]*<script>([\\d\\D]*?)<\\/script>[\\d\\D]*', 'i');
                        inlineScriptSource = pageSource.replace(inlineScriptSourceRegExp, '$1').trim();
                    }

                    for (; i < scripts.length; i++) {
                        if ((scripts[i] as any).readyState === 'interactive') {
                            return scripts[i];
                        }

                        if (scripts[i].src === scriptLocation) {
                            return scripts[i];
                        }

                        if (
                            scriptLocation === currentLocation &&
                            scripts[i].innerHTML &&
                            scripts[i].innerHTML.trim() === inlineScriptSource
                        ) {
                            return scripts[i];
                        }
                    }

                    return null;
                }
            }
        });
    }
})(document);

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
//import 'core-js/es7/reflect';

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 // (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 // (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 // (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
